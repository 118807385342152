@import '../../../theme.scss';

.wrapper {
  display: grid;
  background: transparent;
  grid-template-columns: 48% 48%;
  grid-column-gap: 4%;
  align-items: center;
  font-size: 0.875em;
  color: $gray3;
  width: 70%;
  padding: 0;
}

.item {
  width: 100%;
  padding-left: 5%;
  background: $offWhite;
  border: 1px solid $borderLight;
  color: $gray2;
  box-shadow: none;
  border-radius: 5px;
  text-align: center;
}
