@import '../theme.scss';

.wrapper {
  background: #fdf6f5;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid #e7c0bc;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 15px;
  line-height: 1.4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .container {
    display: flex;
    align-items: center;
    .message {
      > p {
        margin: 0;
        line-height: 1.5;
      }
    }
    .button {
      margin-left: auto;
      button {
        border-color: #da9d96;
        font-size: 12px;
        span {
          padding: 6px 5px !important;
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
