@import '../../../theme.scss';

.root {
  display: flex;
  min-width: 260px;
  max-width: 260px;
}

.client-selector {
  margin: 1px 0 0 -1px;
  border-radius: $borderRadiusBottom;
  width: 110%;
}

.header-item {
  height: 100%;
  width: 100%;
}

.svg {
  height: 18px;
  width: 18px;
  transform: translateY(-2px);
}
