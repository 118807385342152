@import '../../theme.scss';

.wrapper {
  display: flex;
  justify-content: flex-end;
  .per-page-selector {
    margin-left: auto;
  }
  .paginator {
    margin-left: 10px;
    margin-bottom: 20px;
    .container {
      display: flex;
    }
    .segment {
      > span {
        &:focus {
          outline: none;
        }
        &:hover {
          text-decoration: underline;
        }
        cursor: pointer;
        padding: 13px 20px;
        display: inline-block;
        line-height: 1;
        border-radius: $button-borderRadius;
        text-transform: none;
        font-size: $fontSizeMedium;
        font-weight: 600;
        color: $button-default-color;
        background: $button-default-background;
        border: 1px solid $button-default-border;
      }
    }
    .button.disabled {
      @extend .segment;
      > span {
        color: #ced3d6;
        border-color: #e3e5e6;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      }
    }
    .segment.active {
      > span {
        &:hover {
          text-decoration: none;
        }
        cursor: default;
        pointer-events: none;
        color: white;
        background-color: #6c5fc7;
      }
    }
    .ellipsis {
      margin: 9px;
      text-transform: none;
      font-size: $fontSizeMedium;
      font-weight: 600;
    }
  }
}
