@import '../../../theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.dropzone {
  cursor: pointer;
  outline: none;
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 8px;
}

.icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.error {
  width: 100%;
  margin: 0;
}
