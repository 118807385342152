@import '../../../theme.scss';

.root {
  align-self: flex-end;
  height: 100%;
  position: relative;
  div[class*='headerItem_wrapper'] {
    padding-right: 20px;
  }
}

.header-item {
  height: 100%;
  div[class*='headerItem_content'] {
    width: inherit;
    flex: unset;
  }
  div[class*='headerItem_chevron'] {
    margin-top: -5px;
  }
}

.menu {
  left: -1px;
  display: flex;
  background: #fff;
  border: 1px solid $borderDark;
  position: absolute;
  top: 100%;
  min-width: 120%;
  z-index: $zindex-dropdown;
  box-shadow: $dropShadowLight;
  border-radius: $borderRadiusBottom;
  font-size: 0.8em;
  overflow: hidden;
  &.isAbsoluteSelected {
    left: unset;
    right: -1px;
  }
}

.selector-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-shrink: 0;
  width: 220px;
  min-height: 305px;
  &.isAbsoluteSelected {
    width: 160px;
  }
}

.absolute-selector {
  display: flex;
  flex-direction: column;
}
