@import '../../../theme.scss';

.wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  .content {
    flex: 1;
    padding: 20px;
    &.sidebarVisible {
      flex: 3;
      overflow: auto;
    }
    .header {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-bottom: 16px;
      .title {
        font-size: 22px;
        line-height: 22px;
        font-weight: normal;
        color: rgb(74, 62, 86);
        margin: 0;
        .counter {
          margin-left: 4px;
        }
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-grow: 1;
      }
    }
    .table {
      display: flex;
      flex: 0;
      overflow: hidden;
      .container {
        display: flex;
        flex: 1;
        overflow: auto;
      }
    }
    .pagination {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  .sidebar {
    flex: 1;
    padding-right: 20px;
    .filters {
      > div {
        padding: 10px 0;
      }
    }
  }
}
