@import '../../theme.scss';

.wrapper {
  width: inherit;
  height: inherit;
  display: flex;
  .sidebar {
    display: flex;
    flex: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .indicators {
      display: flex;
      flex-direction: column;
      .indicator {
        position: sticky;
        top: 0;
        display: flex;
        height: 35px;
        align-items: center;
        z-index: $zindex-header;
        font-weight: 400;
        padding: 10px;
        font-size: 16px;
        line-height: 1.4;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        > span {
          margin-left: 5px;
        }
        &.warning {
          background: #fdf6f5;
          color: rgba(0, 0, 0, 0.7);
          border: 1px solid #e7c0bc;
        }
        &.info {
          background: #f5fafe;
          color: rgba(0, 0, 0, 0.7);
          border: 1px solid #b5d6ed;
        }
        .html {
          display: flex;
          flex-grow: 1;
          margin-left: 5px;
        }
        .hideOnHover {
          visibility: hidden;
        }
        &:hover {
          .hideOnHover {
            visibility: visible;
          }
        }
      }
    }
    .view {
      display: flex;
      flex: 1;
      overflow: hidden;
    }
  }
}
