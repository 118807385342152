@import '../theme.scss';

.actor {
  position: relative;
  width: 100%;
  /* This is needed to be able to cover dropdown menu so that it looks like one unit */
  &.isOpen {
    z-index: $zindex-dropdownAutoComplete-actor;
  }
}
