@import '../theme.scss';

.wrapper {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  color: $gray4;
  padding: ($grid * 4) px 15%;
  font-size: $fontSizeLarge;
  &.large {
    font-size: $fontSizeExtraLarge;
  }
}

.contentMargin {
  margin-bottom: $space-2;
  &:last-child {
    margin: 0;
  }
}

.inlineSvg {
  @extend .contentMargin;
  display: block;
  color: $gray1;
}

.title {
  @extend .contentMargin;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
  &.description {
    margin-top: -$space-0-5;
  }
}
