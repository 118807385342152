@import '../../../theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  &:not(.loading) {
    min-width: 500px;
  }
  &.loading {
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .message-wrapper {
    padding: 20px;
    border-bottom: 1px solid #d1cad8;
    .message {
      display: flex;
      flex-direction: column;
      .title {
        padding-bottom: 10px;
        font-weight: bold;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    .action:nth-child(n + 1) {
      margin-left: 8px;
    }
  }
}
