@import '../../theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  .container {
    display: flex;
    flex: 1;
    overflow: auto;
    .content {
      max-width: 75%;
      flex: 1;
      padding: 20px;
      .header {
        flex: 1;
        margin-bottom: 16px;
        .title {
          font-size: 22px;
          line-height: 22px;
          font-weight: normal;
          color: rgb(74, 62, 86);
          margin: 0;
        }
      }
      .totalsWrapper,
      .VATWrapper,
      .discountsAndChargesWrapper,
      .detailsWrapper {
        table {
          border: 0;
        }
      }
      .VATWrapper,
      .discountsAndChargesWrapper {
        display: flex;
        flex-direction: column;
      }
      .VATWrapper table:not(:last-of-type),
      .discountsAndChargesWrapper table:not(:last-of-type) {
        margin: 0;
        margin-bottom: -1px;
      }
      .detailsWrapper {
        .oddRow {
          background-color: white;
        }
        .evenRow {
          background-color: $offWhite;
        }
      }
      .attachments {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10px;
        .attachment {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 10px;
          .attachmentIconWrapper {
            .attachmentIcon {
              opacity: 0.4;
              padding: 0 5px;
            }
          }
          &:hover {
            .attachmentIconWrapper {
              .attachmentIcon {
                opacity: 0.8;
              }
            }
          }
          &.locked {
            .attachmentIconWrapper {
              position: relative;
              .deleteAttachmentIcon {
                visibility: hidden;
                position: absolute;
                top: -8px;
                left: 36px;
                &:hover {
                  transform: scale(1.5);
                }
              }
            }
            &:hover {
              .attachmentIconWrapper {
                .deleteAttachmentIcon {
                  visibility: visible;
                }
              }
            }
          }
        }
        .newAttachmentIcon {
          position: absolute;
          bottom: -19px;
          background: $button-primary-color;
          color: $button-primary-background;
          &:hover {
            cursor: pointer;
            background: $button-primary-color;
            color: $button-primary-backgroundActive;
          }
        }
      }
      .actions {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
      }
    }
  }
}
