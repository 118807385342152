@import '../../theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  .sticky-header {
    display: flex;
    position: sticky;
    top: 0;
    min-height: 64px;
    z-index: $zindex-header;
    border-bottom: 1px solid $borderLight;
    background: #fff;
    font-size: 18px;
    justify-content: flex-end;
    .header-item {
      display: flex;
      width: fit-content;
    }
    .header-separator {
      width: 1px;
      background-color: $borderLight;
      margin: $space-2 0;
    }
  }
  .container {
    display: flex;
    flex: 1;
    overflow: auto;
    .content {
      flex: 1;
      padding: 20px;
      &.sidebarVisible {
        flex: 3;
        overflow: auto;
      }
      .header {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin-bottom: 16px;
        .title {
          font-size: 22px;
          line-height: 22px;
          font-weight: normal;
          color: rgb(74, 62, 86);
          margin: 0;
          .counter {
            margin-left: 4px;
          }
        }
        .actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          flex-grow: 1;
        }
      }
      .table {
        display: flex;
        flex: 0;
        overflow: hidden;
        .container {
          display: flex;
          flex: 1;
          overflow: auto;
          .status-container {
            padding: 0 10px;
            .status {
              cursor: default;
              padding: 0.25em 0.4em;
              font-size: 75%;
              font-weight: 700;
              line-height: 1;
              text-align: center;
              white-space: nowrap;
              vertical-align: baseline;
              padding-right: 0.6em;
              padding-left: 0.6em;
              border-radius: 10rem;
              &.BLUE {
                color: rgba(255, 255, 255, 0.87);
                background-color: #1e90ff;
              }
              &.YELLOW {
                color: rgba(0, 0, 0, 0.87);
                background-color: #ffd700;
              }
              &.ORANGE {
                color: rgba(0, 0, 0, 0.87);
                background-color: #ff8c00;
              }
              &.PURPLE {
                color: rgba(255, 255, 255, 0.87);
                background-color: #663399;
              }
              &.RED {
                color: rgba(255, 255, 255, 0.87);
                background-color: #ff4500;
              }
              &.GREEN {
                color: rgba(0, 0, 0, 0.87);
                background-color: #32cd32;
              }
              &.GRAY {
                color: rgba(255, 255, 255, 0.87);
                background-color: #95a5a6;
              }
              &.BLACK {
                color: rgba(255, 255, 255, 0.87);
                background-color: #424242;
              }
            }
          }
        }
      }
      .pagination {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
    .sidebar {
      flex: 1;
      padding-right: 20px;
      .filters {
        > div {
          padding: 10px 0;
        }
      }
    }
  }
}

.attachments-modal-wrapper {
  padding: 20px;
  /* stylelint-disable-next-line no-descending-specificity */
  .container {
    cursor: default;
    display: flex;
    flex-direction: column;
    .header-wrapper {
      .header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .title-wrapper {
          .title-container {
            display: flex;
            flex-direction: column;
            .title {
              font-size: 22px;
              line-height: 22px;
              font-weight: normal;
            }
            .subtitle {
              font-size: 14px;
              line-height: 15px;
              font-weight: normal;
              font-style: italic;
            }
          }
        }
        .close-button {
          margin-left: auto;
          opacity: 0.4;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
            color: #dc143c;
          }
        }
      }
    }
    .body-wrapper {
      padding: 10px;
      .body-container {
        .list-wrapper {
          overflow: auto;
          max-height: 200px;
          .list-container {
            display: flex;
            flex-direction: column;
            .item-wrapper {
              padding: 5px;
              min-width: 200px;
              .item-container {
                .item-content-with-padding {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .filename {
                    margin: 0 5px;
                  }
                  .download-button {
                    margin-left: auto;
                    opacity: 0.4;
                    &:hover {
                      cursor: pointer;
                      opacity: 0.8;
                    }
                  }
                }
              }
              &.isFile {
                &:hover {
                  background-color: #e6e6fa;
                }
              }
            }
          }
        }
      }
    }
    .footer-wrapper {
      .footer-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
