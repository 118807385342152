@import "../theme.scss";

.pageHeading {
  font-size: $headerFontSize;
  line-height: $headerFontSize;
  font-weight: normal;
  color: $gray4;
  margin: 0;
  &.withMargins {
    margin-bottom: $space-3;
    margin-top: $space-1;
  }
}

.headerTitle {
  flex: 1;
  font-size: $headerFontSize;
  line-height: $headerFontSize;
  font-weight: normal;
  color: $gray4;
  margin: 0;
}

.pageContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: $space-2 $space-4 $space-3;
  margin-bottom: -20px; /* <footer> has margin-top: 20px; */
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-2;
}
