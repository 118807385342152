@import '../theme.scss';
@import '../styles/overflowEllipsis.module.scss';

.wrapper {
  display: flex;
  padding: 0 $space-4;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: $gray2;
  transition: 0.1s color;
  user-select: none;
  &.locked {
    cursor: text;
  }
  &.isOpen,
  &.hasSelected {
    color: $gray4;
  }
}

.icon-container {
  color: $gray2;
  margin-right: $space-1-5;
  &.hasSelected {
    color: $gray4;
  }
}

.content {
  @extend .overflow-ellipsis;
  flex: 1;
  margin-right: $space-1-5;
}

.close {
  margin-right: $space-1-5;
  color: $gray2;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hasSelected {
    color: $gray4;
  }
}

.link {
  color: $gray2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-1;
  transition: 0.5s opacity ease-out;
  &:hover {
    color: $gray4;
  }
}

.icon-settings {
  height: 16px;
  width: 16px;
}

.chevron {
  transform: rotate(0deg);
  transition: 0.1s all;
  display: flex;
  align-items: center;
  justify-content: center;
  &.isOpen {
    transform: rotate(180deg);
  }
}

.lock {
  color: $gray2;
  width: $space-2;
  height: $space-2;
  stroke-width: 1.5;
}
