@import './theme.scss';

$theme-colors: (
  'primary': $purple,
);

@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 24px;
  color: $gray5;
  background: $white-dark;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  div[id='root'] {
    width: 100%;
    height: 100%;
  }
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik-regular.woff') format('woff2'), url('./fonts/rubik-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik-medium.woff') format('woff2'), url('./fonts/rubik-medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

// Sentry icon font

@font-face {
  font-family: 'sentry-simple';
  src: url('./fonts/sentry-simple.eot?-iwi56z');
  src: url('./fonts/sentry-simple.eot?#iefix-iwi56z') format('embedded-opentype'),
    url('./fonts/sentry-simple.woff?-iwi56z') format('woff'), url('./fonts/sentry-simple.woff2?-lhf5py') format('woff2'),
    url('./fonts/sentry-simple.ttf?-iwi56z') format('truetype'),
    url('./fonts/sentry-simple.svg?-iwi56z#sentry-simple') format('svg');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-weight: 600;
}
