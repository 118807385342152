@import '../theme.scss';

.container {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1) inset;
  border: 1px solid $gray1;
  &:hover {
    border: 1px solid $gray2;
  }
  &.checked {
    background: $purple;
    border: 1px solid $purple;
    &:hover {
      border: 1px solid $purple;
    }
  }
}

.check {
  width: 70%;
  height: 70%;
  color: #fff;
}
