@import '../../theme.scss';

.panel {
  background: #fff;
  border-radius: $borderRadius;
  border: 1px solid $borderDark;
  box-shadow: $dropShadowLight;
  position: relative;
  margin-bottom: 20px;
  &.dashedBorder {
    background: $offWhite;
    border: 1px dashed $gray2;
    box-shadow: none;
  }
  &.noBorder {
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  &.noMargin {
    margin-bottom: 0;
  }
}

.panelHeader {
  display: flex;
  color: $gray3;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid $borderDark;
  border-radius: $borderRadius $borderRadius 0 0;
  background: $offWhite;
  line-height: 1;
  position: relative;
  padding: $space-2 $space-2;

  &.noBorder {
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &.lightText {
    color: $gray2;
  }

  &.hasButtons {
    padding-left: $space-1;
    padding-right: $space-1;
  }

  &.disablePadding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.panelBody {
  &.withPadding {
    padding: $space-2;
  }
}

.panelItem {
  display: flex;
  border-bottom: 1px solid $borderLight;
  &:last-child {
    border: 0;
  }

  .panelAlert {
    margin: -1px;
    border-radius: 0;
  }
}

.panelFooter {
  border-top: 1px solid $borderDark;
  color: $gray3;
  font-size: 14px;
}
