@import '../theme.scss';

.view-wrapper {
  background-color: $whiteDark;
  width: 100%;
  height: inherit;
}

.view-wrapper-content {
  height: inherit;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .box {
    max-width: 630px;
    .title {
      flex-direction: row;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .icon {
        color: $redDark;
      }
      .message {
        padding-left: 15px;
      }
    }
    .body {
      margin-bottom: 15px;
    }
    .actions {
      float: right;
      > * {
        margin: 0 5px;
      }
    }
  }
}
