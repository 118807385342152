@import '../theme.scss';

@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes alertHighlight-info {
  0%,
  100% {
    background: rgba(255, 255, 255, 0);
    border-color: transparent;
  }
  25% {
    background: $alert-info-backgroundLight;
    border-color: $alert-info-border;
  }
}
