@import '../theme.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  padding-left: $space-0-5;
  height: $headerSelectorRowHeight + 'px';
  flex-shrink: 0;

  /* thanks bootstrap? */
  input[type='checkbox'] {
    margin: 0;
  }
}

.content {
  display: flex;
  flex-shrink: 1;
  overflow: hidden;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  user-select: none;
  /* &.multi {
    &:hover {
      text-decoration: underline;
      color: $blue;
    }
  } */
}

.checkbox {
  transition: 0.2s transform;
}

.checkbox-wrapper {
  /* margin: 0 -$space-1 0 0; */ /* pushes the click box to be flush with the edge of the menu */
  padding: 0 $space-1-5 0 $space-1-25;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s all;

  &:hover .checkbox {
    transform: scale(1.1);
    border-color: $gray2;
    &.checked {
      border-color: $purple;
    }
  }
}
