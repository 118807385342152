@import '../../theme.scss';

.toast {
  min-height: 40px;
  padding: 0 15px 0 10px;
  margin-top: 15px;
  background: $gray5;
  color: #fff;
  border-radius: 44px 7px 7px 44px;
  box-shadow: 0 4px 12px 0 rgba(47, 40, 55, 0.16);
}
