@import '../theme.scss';

$loader-size: 64px;
$check-height: 32px;
$check-width: 16px;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 6px;

.loading {
  margin: auto;
  position: relative;

  &.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    &.dark {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  > .loading-indicator {
    position: relative;
    border: $check-thickness solid $white-darkest;
    border-left-color: $purple;
    -webkit-animation: loading 0.5s infinite linear;
    animation: loading 0.55s infinite linear;
    margin: 0 auto;

    > .checkmark {
      &.draw:after {
        animation-duration: 800ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg);
      }

      &:after {
        opacity: 1;
        height: $check-height;
        width: $check-width;
        transform-origin: left top;
        border-right: $check-thickness solid $green;
        border-top: $check-thickness solid $green;
        content: '';
        left: 10px;
        top: 30px;
        position: absolute;
      }
    }
  }

  > .loading-indicator,
  > .loading-indicator:after {
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
  }

  > .loading-message {
    margin-top: 20px;
    text-align: center;
  }

  > .load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: $green;
    transition: border 500ms ease-out;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// mini

.loading.mini {
  margin: 4px 0;
  font-size: 13px;

  > .loading-indicator {
    margin: 0;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border-width: 2px;
    position: absolute;
    left: 0;
    top: 0;

    &.relative {
      position: relative;
      left: auto;
      top: auto;
    }
  }

  > .loading-message {
    padding-left: 30px;
    margin-top: 1px;
    display: inline-block;
  }
}

// Loading Gif

/* .loading.triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  margin-top: -200px;
  margin-left: -250px;

  .loading-indicator {
    border: 0;
    animation: none;
    -webkit-animation: none;
    background-image: url(../images/sentry-loader.gif);
    .square(150px);
    background-size: contain;
  }
} */
