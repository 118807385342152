@import '../theme.scss';

.label-with-border {
  background-color: $offWhite;
  border-bottom: 1px solid $borderLight;
  border-width: 1px 0;
  color: $gray3;
  font-size: $fontSizeMedium;

  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
}

.group-label {
  padding: $space-0-25 $space-1;
}

.autocomplete-item {
  /* needed for virtualized lists that do not fill parent height */
  /* e.g. breadcrumbs (org height > project, but want same fixed height for both) */
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 0.9em;
  background-color: transparent;
  padding: $space-1;
  cursor: pointer;
  border-bottom: 1px solid $borderLight;

  &.small {
    padding: $space-0-5 $space-1;
  }
  &.zero {
    padding: 0;
  }
  &.highlightedIndex {
    background-color: $offWhite;
  }

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: $offWhite;
  }
}

.autocomplete-root {
  position: relative;
  // display: inline-block;
}

.empty-message {
  color: $gray1;
  padding: $space-2;
  text-align: center;
  text-transform: none;
}

.input-wrapper {
  display: flex;
  border-bottom: 1px solid $borderLight;
  border-radius: $borderRadius $borderRadius 0 0;
  align-items: center;
}

.input {
  flex: 1;
  border: 1px solid transparent;

  &,
  &:focus,
  &:active,
  &:hover {
    border: 0 !important;
    box-shadow: none;
    font-size: 13px;
    padding: $space-1;
    font-weight: normal;
    color: $gray2;
  }
}

.input-loading-wrapper {
  display: flex;
  background: #fff;
  align-items: center;
  flex-shrink: 0;
  width: 30px;
}

.label {
  &.withBorder {
    background-color: $offWhite;
    border-bottom: 1px solid $borderLight;
    border-width: 1px 0;
    color: $gray3;
    font-size: $fontSizeMedium;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &.withPadding {
    padding: $space-0-25 $space-1;
  }
}

.menu {
  background: #fff;
  border: 1px solid $borderDark;
  position: absolute;
  top: calc(100% - 1px);
  min-width: 250px;
  z-index: $zindex-dropdownAutoComplete-menu; /* This is needed to be able to cover e.g. pagination buttons, but also be below dropdown actor button's zindex */
  right: 0;
  box-shadow: $dropShadowLight;
  overflow: hidden;
  border-radius: 0 0 $borderRadius $borderRadius;
  &.alignLeft {
    left: 0;
  }
  &.blendCorner {
    &:not(.alignLeft) {
      border-top-left-radius: $borderRadius;
    }
    &.alignLeft {
      border-top-right-radius: $borderRadius;
    }
  }
  &.menuWithArrow {
    top: 32px;
    &::before {
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid rgba(52, 60, 69, 0.35);
      content: '';
      display: block;
      position: absolute;
      top: -9px;
      left: 10px;
      z-index: -2;
      &.alignRight {
        left: auto;
        right: 10px;
      }
    }
    &:after {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
      content: '';
      display: block;
      position: absolute;
      top: -8px;
      left: 11px;
      z-index: -1;
      &.alignRight {
        left: auto;
        right: 11px;
      }
    }
  }
}
