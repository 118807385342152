@import '../theme.scss';

.button {
  display: inline-block;
  line-height: 1;
  border-radius: $button-borderRadius;
  padding: 0;
  text-transform: none;
  font-size: $fontSizeMedium;
  font-weight: 600;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.priority-default {
    color: $button-default-color;
    background: $button-default-background;
    border: 1px solid $button-default-border;
  }
  &.priority-primary {
    color: $button-primary-color;
    background: $button-primary-background;
    border: 1px solid $button-primary-border;
  }
  &.priority-danger {
    color: $button-danger-color;
    background: $button-danger-background;
    border: 1px solid $button-danger-border;
  }
  &.priority-link {
    color: $button-link-color;
    background: $button-link-background;
    border: none;
    font-weight: 400;
  }
  &.priority-success {
    color: $button-success-color;
    background: $button-success-background;
    border: 1px solid $button-success-border;
  }
  &:hover {
    &.priority-default {
      color: $button-default-color;
    }
    &.priority-primary {
      color: $button-primary-color;
    }
    &.priority-danger {
      color: $button-danger-color;
    }
    &.priority-link {
      color: $button-link-color;
    }
    &.priority-success {
      color: $button-success-color;
    }
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    &.priority-default {
      color: $button-default-colorActive;
      background: $button-default-backgroundActive;
      border: 1px solid $button-default-borderActive;
    }
    &.priority-primary {
      color: $button-primary-color;
      background: $button-primary-backgroundActive;
      border: 1px solid $button-primary-borderActive;
    }
    &.priority-danger {
      color: $button-danger-color;
      background: $button-danger-backgroundActive;
      border: 1px solid $button-danger-borderActive;
    }
    &.priority-link {
      text-decoration: underline;
      color: $button-link-color;
      background: $button-link-backgroundActive;
    }
    &.priority-success {
      color: $button-success-color;
      background: $button-success-backgroundActive;
      border: 1px solid $button-success-borderActive;
    }
  }
  &.busy {
    opacity: 0.65;
  }
  &.disabled {
    opacity: 0.65;
    color: #ced3d6;
    border-color: #e3e5e6;
    box-shadow: none;
    cursor: default;
    pointer-events: none;
  }
  &.borderless {
    border-color: transparent;
    font-weight: 400;
  }
  &.size-xsmall {
    font-size: $fontSizeSmall;
  }
  &.size-small {
    font-size: $fontSizeSmall;
  }
  &.size-large {
    font-size: $fontSizeLarge;
  }
  > .label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    &.borderless {
      padding: 6px 10px;
    }
    &.size-zero {
      padding: 0;
    }
    &.size-xsmall {
      padding: 6px 10px;
      &.borderless {
        padding: 4px 6px;
      }
      .with-margin {
        margin-right: 6px;
      }
    }
    &.size-small {
      padding: 8px 12px;
      &.borderless {
        padding: 6px 8px;
      }
      .with-margin {
        margin-right: 6px;
      }
    }
    &.size-large {
      padding: 14px 20px;
      &.borderless {
        padding: 8px 10px;
      }
      .with-margin {
        margin-right: 8px;
      }
    }
  }
  .icon {
    display: block;
  }
}
