.container {
    padding-top: 5vh;
    max-width: 740px;
}

.box {
    display: flex;
    width: 100%;
    background: #fff;
    border: 1px solid #e2dee6;
    border-radius: 3px;
    margin: 0 0 20px;
    box-shadow: 0 1px 0 rgba(0,0,0,.03);
}

.sidebar {
    padding-top: 20px;
    width: 60px;
    background: #564f64;
    background-image: linear-gradient(-180deg,rgba(52,44,62,0),rgba(52,44,62,.5));
    box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
    border-radius: 4px 0 0 4px;
    margin-top: -1px;
    margin-bottom: -1px;
    text-align: center;
}

.main {
  flex: 1;
}
