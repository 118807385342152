@import '../../theme.scss';

.table {
  width: 100%;
  background: $white;
  border: 1px solid $borderDark;
  border-radius: $borderRadius;
  box-shadow: $dropShadowLight;
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  font-size: medium;

  // display: flex;
  // flex-flow: column;
  // thead,
  // tbody tr {
  //   display: table;
  //   table-layout: fixed;
  // }
  // thead {
  //   flex: 0 0 auto;
  //   width: 100%;
  //   tr {
  //     width: calc(100% - 0.9em); /* 0.9em approximates scrollbar width */
  //     display: table;
  //   }
  // }
  // tbody {
  //   display: block;
  //   flex: 1 1 auto;
  //   overflow-y: scroll;
  //   tr {
  //     width: 100%;
  //   }
  // }
}

.table-header {
  color: $gray3;
  font-weight: 600;
  font-size: smaller;
  text-transform: uppercase;
  border-bottom: 1px solid $borderDark;
  background: $offWhite;
  line-height: 1;
  position: relative;
  padding: $space-2 $space-2;
}

.table-header-cell {
  padding: 10px;
  text-align: center;
}

.table-body {
  width: 100%;
  font-size: smaller;
}

.table-body-odd-row {
  background-color: white;
}

.table-body-even-row {
  background-color: $offWhite;
}

.table-body-cell {
  padding: 8px;
  text-align: center;
  &:last-child {
    padding: 0;
  }
}

.sort-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: black;
  }
}

.sort-icon {
  vertical-align: bottom;
  margin-left: 5px;
}

.table-body-loader {
  margin: 50px;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.action-button {
  cursor: pointer;
  opacity: 0.4;
  padding: 0 5px;
  &:hover {
    opacity: 0.8;
  }
}
