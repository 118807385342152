@import '../../../theme.scss';

.checkbox {
  transition: 0.2s transform;
}

.checkbox-wrapper {
  /* margin: 0 -$space-1 0 0; */ /* pushes the click box to be flush with the edge of the menu */
  padding: 0 $space-1-5 0 $space-1-25;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s all;

  &:hover .checkbox {
    transform: scale(1.1);
    border-color: $gray2;
    &.checked {
      border-color: $purple;
    }
  }
}
