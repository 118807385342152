@import '../../../theme.scss';

.field-wrapper {
  display: flex;
  box-sizing: border-box;
  transition: background 0.15s;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid $borderLight;
  padding: $space-2 $space-2 $space-2 $space-2;
  background-color: white;

  &.withoutSeparator {
    border-bottom: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &.stacked {
    border-bottom: none;
    padding: 0 $space-2 $space-1 0;
    &:last-child {
      padding-bottom: 0;
    }
  }

  &.inline {
    flex-direction: row;
    align-items: center;
  }

  &.highlighted {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid $purple;
      pointer-events: none;
    }
  }
}

.description {
  font-weight: normal;
  margin-bottom: $space-1;
  &.inline {
    width: 50%;
    padding-right: 10px;
    flex-shrink: 0;
  }
}

.field-label {
  color: $gray5;
  &.disabled {
    color: $gray2;
  }
}

.field-help {
  color: $gray2;
  font-size: 14px;
  margin-top: $space-1;
  line-height: 1.4;
}

.field-required-badge {
  display: inline-block;
  background: $redLight;
  opacity: 0.6;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  text-indent: -9999em;
  vertical-align: super;
}

.field-control-wrapper {
  display: flex;
  box-sizing: border-box;
  &.inline {
    width: 50%;
    padding-left: 10px;
    flex: 1 1 0%;
  }
}

.field-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 5px;
}

.field-control-state {
  display: flex;
  position: relative;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 36px;
  &.flexibleControlStateSize {
    width: unset;
  }
}
