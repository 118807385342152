@import '../theme.scss';

.wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  .content {
    flex: 1;
    padding: 20px;
    &.sidebarVisible {
      flex: 3;
      overflow: auto;
    }
    .header {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-bottom: 16px;
      .title {
        font-size: 22px;
        line-height: 22px;
        font-weight: normal;
        color: rgb(74, 62, 86);
        margin: 0;
        .counter {
          margin-left: 4px;
        }
      }
    }
    .table {
      display: flex;
      flex: 0;
      overflow: hidden;
      .container {
        display: flex;
        flex: 1;
        overflow: auto;
        .notification-wrapper {
          display: flex;
          min-height: 80px;
          .notification-container {
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-evenly;
            .html {
              height: 100%;
              display: flex;
              flex-grow: 1;
            }
            .additional-wrapper {
              height: 100%;
              margin-left: auto;
              width: fit-content;
              .additional-container {
                height: inherit;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                .actions {
                  visibility: hidden;
                  margin-top: -10px;
                  button {
                    > span {
                      font-size: smaller;
                    }
                  }
                }
                .infos {
                  justify-self: flex-end;
                  font-size: smaller;
                  .info {
                    font-style: italic;
                  }
                }
              }
            }
            &.unread {
              font-size: larger;
              font-weight: bold;
              &:hover {
                > .additional-wrapper > .additional-container > .actions {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  }
}
