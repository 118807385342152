@import '../../theme.scss';

.wrapper {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: $zindex-header;
  padding: $space-3 $space-4;
  border-bottom: 1px solid $borderLight;
  background: #fff;
  .container {
    display: flex;
    flex-grow: 1;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;

  .crumb {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 18px;
    color: $gray3;
    padding-right: $space-1;
    cursor: pointer;
    > span {
      transition: 0.1s all ease;
    }

    &:hover {
      color: $gray5;
    }
  }

  .divider {
    display: inline-block;
    margin-left: 6px;
    color: $borderDark;
    position: relative;
    top: -1px;
  }
}
