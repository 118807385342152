@import '../theme.scss';

.container {
  display: inline-block;
  border: 1px solid $borderLight;
  position: relative;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.04);
  transition: 0.15s border ease;
  cursor: pointer;

  &.active {
    border: 1px solid $borderDark;
  }

  &.isLoading,
  &.isDisabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.sm {
    height: 16px;
    width: 32px;
    border-radius: 16px;
  }

  &.lg {
    height: 24px;
    width: 48px;
    border-radius: 48px;
  }

  &:hover {
    border-color: $borderDark;
  }

  .toggle {
    display: block;
    position: absolute;
    border-radius: 50%;
    transition: 0.25s all ease;
    background: $gray6;

    &.sm {
      top: 2px;
      width: 10px;
      height: 10px;
      transform: translateX(2px);
      &.isActive {
        transform: translateX(18px);
      }
    }

    &.lg {
      top: 4px;
      width: 14px;
      height: 14px;
      transform: translateX(4px);
      &.isActive {
        transform: translateX(28px);
      }
    }

    &.isActive {
      background: $green;
    }

    &.isDisabled {
      opacity: 0.4;
    }
  }
}
