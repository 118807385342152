@import "../theme.scss";

$alert-warning-bg-color: #fffdf7;
$alert-warning-border-color: #e1d697;

$alert-danger-bg-color: #fdf6f5;
$alert-danger-border-color: #e7c0bc;

$alert-info-bg-color: #f5fafe;
$alert-info-border-color: #b5d6ed;

$alert-success-bg-color: #f8fcf7;
$alert-success-border-color: #bbd6b3;

.alert-block ul {
  padding-left: 20px;
}
// ugh this is awful
.alert-block p + ul,
.alert-block p + p,
.alert-block ul + p {
  margin-top: 10px !important;
}


.alert {
  background: $alert-warning-bg-color;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid $alert-warning-border-color;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  p:last-child {
    margin: 0;
  }

  .icon {
    float: left;
    margin: 3px 8px 0 3px;
    display: block;
    font-size: 15px;
    opacity: 0.65;
  }

  .icon-sentry-logo {
    display: block;
  }

  .close {
    font-weight: 600;
    position: absolute;
    top: -6px;
    right: 8px;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    background: 0;
    border: 0;
    font-size: 22px;
    float: right;

    &:hover {
      box-shadow: none;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  & + .alert {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.15);
  }

  &.alert-block {
    margin-bottom: 20px;
    border-radius: 3px;
  }

  .btn-default {
    border-color: desaturate(darken($alert-warning-border-color, 10), 10);
  }
}

.alert-block {
  .btn {
    float: right;
    position: relative;
    top: -3px;
    right: -6px;
  }
}


.alert-error,
.alert-danger {
  background: $alert-danger-bg-color;
  border-color: $alert-danger-border-color;

  .icon:before {
    content: '\e615';
  }

  .btn-default {
    border-color: darken($alert-danger-border-color, 10);
  }
}

.alert-info {
  background: $alert-info-bg-color;
  border-color: $alert-info-border-color;
  color: $gray5;

  .icon {
    display: none;
  }

  .btn-default {
    border-color: desaturate(darken($alert-info-border-color, 10), 10);
  }
}

.alert-success {
  background: $alert-success-bg-color;
  border-color: $alert-success-border-color;

  .icon:before {
    content: '\e60a';
  }

  .btn-default {
    border-color: darken($alert-success-border-color, 5);
  }
}
