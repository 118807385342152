@import '../theme.scss';

.wrapper {
  text-align: center;
  font-size: 22px;
  padding: 24px 0;

  > p {
    line-height: 1.2;
    margin: 10px auto 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  > .hero-icon {
    margin-bottom: 20px;
  }
}

.small {
  display: flex;
  align-items: center;
  color: $gray2;
  font-size: $fontSizeExtraLarge;
  line-height: 1em;
}
