@import '../theme.scss';

.modal-wrapper {
  min-width: 500px;
  .container {
    .header {
      .title {
        padding: 15px;
        > h4 {
          margin: 0;
        }
      }
    }
    .body {
      .field {
        .loading {
          > * {
            position: initial !important;
          }
        }
        > label {
          width: 26%;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  .content {
    flex: 1;
    padding: 20px;
    &.sidebarVisible {
      flex: 3;
      overflow: auto;
    }
    .header {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-bottom: 16px;
      .title {
        font-size: 22px;
        line-height: 22px;
        font-weight: normal;
        color: rgb(74, 62, 86);
        margin: 0;
        .counter {
          margin-left: 4px;
        }
      }
    }
    .table {
      display: flex;
      flex: 0;
      overflow: hidden;
      .container {
        display: flex;
        flex: 1;
        overflow: auto;
      }
    }
    .pagination {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  }
}

.status-container {
  padding: 0 10px;
  .status {
    cursor: default;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    &.BLUE {
      color: rgba(255, 255, 255, 0.87);
      background-color: #1e90ff;
    }
    &.ORANGE {
      color: rgba(0, 0, 0, 0.87);
      background-color: #ff8c00;
    }
    &.RED {
      color: rgba(255, 255, 255, 0.87);
      background-color: #ff4500;
    }
    &.GREEN {
      color: rgba(0, 0, 0, 0.87);
      background-color: #32cd32;
    }
    &.BLACK {
      color: rgba(255, 255, 255, 0.87);
      background-color: #424242;
    }
  }
}
