@import '../../theme.scss';

.filter {
  .filter-header {
    color: lighten($gray, 10);
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1px;
  }
  .filter-form-control {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.04);
    width: 100%;
    height: auto;
    border: 1px solid lighten($gray-lighter, 10);
    position: relative;
    border-radius: 3px;
    color: $gray-dark;

    &.disabled {
      border: 1px solid $trim;
      background: #f7f8f9;
      color: $gray-dark;
    }

    &::-webkit-input-placeholder {
      color: $gray-light;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $gray-light;
    }
    &:-moz-placeholder {
      color: $gray-light;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $gray-light;
    }

    &:focus {
      border-color: darken($gray-lighter, 4);
      box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.04), 0 0 6px rgba(177, 171, 225, 0.3);
      outline: none;
    }

    object {
      position: absolute;
      top: 10px;
      right: 9px;
    }
  }
}

.loading {
  > * {
    position: initial !important;
  }
}
