@import '../../theme.scss';

.actions {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.checkbox {
  transition: 0.2s transform;
}

.checkbox-wrapper {
  /* margin: 0 -$space-1 0 0; */ /* pushes the click box to be flush with the edge of the menu */
  padding: 0 $space-1-5 0 $space-1-25;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s all;

  &:hover .checkbox {
    transform: scale(1.1);
    border-color: $gray2;
    &.checked {
      border-color: $purple;
    }
  }
}

.interceptionFilters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    border-bottom: 1px solid #e2dbe8;
    padding: 16px 16px 16px 16px;
  }
}

.newFilterIcon {
  position: absolute;
  bottom: -18px;
  background: $button-primary-color;
  color: $button-primary-background;
  &:hover {
    cursor: pointer;
    background: $button-primary-color;
    color: $button-primary-backgroundActive;
  }
}

.close-button {
  margin-left: 20px;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    color: #dc143c;
  }
}
