@import '../../../theme.scss';
@import '../../../styles/animations.module.scss';

.item {
  padding: 0;
}

.create-button {
  display: block;
  text-align: center;
  margin: $space-0-5 0;
}

.add-button {
  display: block;
  margin: 0 $space-1;
  color: $gray2;
  border: 1px solid $gray1;
  transition: 0.2s ease-in-out;
  &:hover {
    color: $gray3;
    border-color: darken($gray1, 5);
  }
}

.add-button-icon {
  margin-right: $space-0-5;
}

.badge-wrapper {
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
}

.badge-and-actions-wrapper {
  animation: none;
  z-index: inherit;
  position: relative;
  border-style: solid;
  border-width: 1px 0;
  border-color: transparent;
  /* &:hover ${StyledBookmarkStar}, &:hover ${SettingsIconLink} {
    opacity: 1;
  } */
  &.bookmarkHasChanged {
    animation: 1s alertHighlight-info;
    z-index: 1;
  }
}
