@import '../../theme.scss';

.wrapper {
  display: flex;
  .container {
    opacity: initial !important;
    background: white;
    border-radius: 5px;
    min-width: fit-content;
  }
}
