@import '../../theme.scss';

.input {
  color: $gray5;
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid $borderDark;
  border-radius: $borderRadius;
  box-shadow: inset $dropShadowLight;
  padding: 0.5em;
  transition: border 0.1s linear;
  resize: vertical;

  &.monospace {
    font-family: $font-family-mono;
  }

  &.readOnly {
    cursor: default;
  }

  &.disabled {
    background: $whiteDark;
    color: $disabled;
    border: 1px solid $borderDark;
    cursor: not-allowed;

    &::placeholder {
      color: $disabled;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $gray1;
  }

  &::placeholder {
    color: $gray2;
  }
}
