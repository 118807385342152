@import '../../theme.scss';

.sidebar {
  height: inherit;
  width: 220px;
  background: #fff;
  border-right: 1px solid $borderLight;
  padding: $space-4;

  .section {
    margin-bottom: 20px;
  }

  .heading {
    color: $gray3;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .item {
    display: block;
    color: $gray2;
    font-size: 14px;
    line-height: 30px;
    position: relative;

    &.active {
      color: $gray5;

      &:before {
        background: $purple;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: $gray5;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 4px;
      left: -30px;
      height: 20px;
      width: 4px;
      background: transparent;
      border-radius: 0 2px 2px 0;
    }
  }
}
