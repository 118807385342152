@import '../../../../theme.scss';

.wrapper {
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  white-space: nowrap;
  padding: $space-1;
  align-items: center;
  background-color: transparent;
  font-weight: normal;
  border-bottom: 1px solid $borderLight;
  &:hover {
    background: $offWhite;
  }
  &.isSelected {
    background-color: $offWhite;
    font-weight: bold;
  }
  &.isLast {
    border-bottom: 1px solid transparent;
  }
}

.label {
  display: flex;
  flex-grow: 1;
  margin-right: $space-1;
}
