@import '../../theme.scss';

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  .header {
    display: flex;
    position: sticky;
    top: 0;
    min-height: 64px;
    z-index: $zindex-header;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    .sidebar {
      display: flex;
      flex: 0;
      min-width: 220px;
      max-width: fit-content;
    }
    .view {
      display: flex;
      flex: 1;
      overflow: hidden;
    }
  }
}
