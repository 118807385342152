@import '../../theme.scss';
@import '../../styles/overflowEllipsis.module.scss';

.wrapper {
  display: flex;
  align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    line-height: 1;
    overflow: hidden;
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
    }
    .description {
      @extend .overflow-ellipsis;
      font-size: 0.875em;
      margin-top: $space-0-25;
      color: $gray2;
      line-height: 14px;
    }
  }
}
