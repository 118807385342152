@import '../../../theme.scss';

.wrapper {
  flex: 1;
  flex-direction: column;
  overflow: auto;
  .title {
    font-size: 20px;
    font-weight: bold;
    margin: $space-2 $space-4;
  }
  .content {
    width: 80%;
    min-width: 0;
    padding: $space-2 $space-4;
  }
}
