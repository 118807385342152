@import '../../theme.scss';

.sidebar {
  height: initial !important;
  background: linear-gradient($gray4, $gray5);
  color: #9586a5;
  line-height: 1;
  padding: 12px 0px 2px; /* Allows for 32px avatars  */
  max-width: 230px;
  top: 0;
  left: 0;
  bottom: 0;
  justify-content: space-between;
  z-index: $zindex-sidebar;
  display: flex;
  flex-direction: column;
}

.sidebar.hasBadges {
  // min-width: unset;
  // max-width: unset;
  max-width: 250px;
}

.sidebar.collapsed {
  // min-width: unset;
  // max-width: unset;
  max-width: 70px;
}

.sectionGroup {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.section {
  @extend .sectionGroup;
  margin: 8px 0;
  margin: 8px 0px;
  padding: 0px 19px;
}

.section.noMargin {
  margin: 0;
}

.section.nav {
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1 1 0%;
  > .sectionGroup {
    min-height: 0;
    //flex-shrink: initial;
  }
}

@media (max-height: 418px) and (min-width: 768px) {
  .section.nav {
    padding-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px -10px 10px inset;
    border-bottom: 1px solid rgb(100, 85, 116);
  }
}

.item {
  display: flex;
  color: inherit;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  line-height: 32px;
  height: 34px;
  flex-shrink: 0;

  transition: 0.15s color linear;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 4px;
    left: -20px;
    bottom: 6px;
    width: 5px;
    border-radius: 0 3px 3px 0;
    background-color: transparent;
    transition: 0.15s background-color linear;
  }

  &:hover,
  &:focus {
    color: $gray1;
    text-decoration: none;
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .icon {
      content: '';
      display: inline-block;
      width: 32px;
      height: 22px;
      font-size: 20px;

      svg {
        display: block;
        margin: 0 auto;
      }
    }
    .label {
      margin-left: 12px;
      white-space: nowrap;
      opacity: 1;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .badge {
      display: block;
      text-align: center;
      color: $white;
      font-size: 12px;
      background: $red;
      width: $sidebar-badgeSize;
      height: $sidebar-badgeSize;
      border-radius: $sidebar-badgeSize;
      line-height: $sidebar-badgeSize;
      min-width: fit-content;
      padding: 0 5px;
      max-width: 40px;

      &.collapsed {
        text-indent: -99999em;
        position: absolute;
        right: 0;
        top: 1px;
        background: $red;
        width: $sidebar-smallBadgeSize;
        height: $sidebar-smallBadgeSize;
        border-radius: $sidebar-smallBadgeSize;
        line-height: $sidebar-smallBadgeSize;
        box-shadow: 0 3px 3px $purpleSuperDark;
      }
    }
  }
}

.item.active {
  color: $white;

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }

  &:before {
    background-color: $purple;
  }
}

// TODO move this
.avatar {
  flex-shrink: 0;
  margin-right: 12px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  vertical-align: middle;
  position: relative;
  display: inline-block;
  &.collapsed {
    margin-right: 0;
  }
}

.dropdownActor {
  display: flex;
  align-items: flex-start;
}

.identity {
  display: flex;
  align-items: center;
  > .infos {
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin: 2px 0;
    > .username {
      line-height: 1.2;
      font-weight: bold;
      font-size: 16px;
      color: white;
      text-shadow: rgba(255, 255, 255, 0) 0px 0px 6px;
      transition: text-shadow 0.15s linear 0s;
    }
    > .email {
      font-size: 14px;
      line-height: 16px;
      transition: color 0.15s linear 0s;
    }
  }
}

.dropdownMenu {
  top: 42px;
  min-width: 180px;
  z-index: $zindex-orgAndUserMenu;
  position: absolute;
  background: $white;
  color: $gray5;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 4px 20px 0 rgba(0, 0, 0, 0.3);
  padding: 5px 0;
  width: 250px;
}
