@import '../theme.scss';

.header {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  border-bottom: 1px solid $gray1;
  h3 {
    margin-bottom: 20px;
  }
}

.authContainer {
  width: 60%;
  padding: 30px 40px 40px 40px;
}

.footer {
  .btn {
    margin-right: 6px;
  }
}

.alert {
  margin-bottom: 20px;
}
