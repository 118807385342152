@import '../../theme.scss';

.wrapper {
  height: inherit;
  flex: 1;
  flex-direction: column;
  background-color: $whiteDark;
}

.loading-wrapper {
  flex: 1;
  .loading-container {
    height: 100%;
    display: flex;
  }
}

.header-wrapper {
  min-height: 59px;
  z-index: $zindex-header;
  border-bottom: 1px solid $borderLight;
  background: #fff;
  &.sticky {
    position: sticky;
    top: 0;
  }
  .header-container {
    min-height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 18px;
    .header-item {
      display: flex;
      width: fit-content;
    }
    .header-separator {
      width: 1px;
      background-color: $borderLight;
      margin: $space-2 0;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  margin: 0 30px 20px 30px;

  footer {
    max-height: 20px;
    grid-column: 1 / 4;
  }

  .widget {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &.double {
      grid-column: 1 / 3;
    }

    h3.title {
      flex-grow: 0;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      padding: $space-2 $space-4;
      border-bottom: 1px solid $borderLight;
      margin-bottom: 0;
    }

    .body {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &.centered {
        align-self: center;
      }
      .cell {
        flex-grow: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .gauge {
          max-width: 180px;
          align-self: center;
        }
        .title {
          font-size: 13px;
          text-transform: uppercase;
          text-align: center;
        }
      }
      .divider {
        flex-grow: 0;
        width: 1px;
        border-right: 1px solid;
        margin-bottom: 10px;
        margin-top: 10px;
        border-color: $borderLight;
      }
    }
  }
}

.list {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  h3 {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    padding: $space-2 $space-4;
    border-bottom: 1px solid $borderLight;
    margin-bottom: 0;
  }
  .item {
    border-bottom: 1px solid $borderLighter;
    padding: $space-1 $space-4;
    display: flex;
    flex-direction: row;
    &.fullHeight {
      flex-grow: 1;
    }
    .position {
      display: flex;
      align-items: center;
      width: 2em;
      text-align: right;
      padding-right: 1em;
    }
    .item-content {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      text-overflow: ellipsis;
      .value {
        text-align: right;
        font-weight: bold;
      }
    }
  }
}

.header {
  max-height: fit-content;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  background-color: #efecf3;
  justify-content: space-between;
  padding: 30px 50px 20px 50px;
  flex: 1 0 auto;

  .block {
    display: flex;
    flex-direction: row;
    .icon {
      margin-right: 10px;
      color: $purple;
    }
    .block-content {
      display: flex;
      flex-direction: column;
      font-size: 28px;
      color: $gray1;
      .block-title {
        font-size: 20px;
        font-weight: normal;
        color: $gray3;
        text-transform: uppercase;
        margin: 0;
      }
      .block-info {
        margin-top: -5px;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .kpis {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 10px;
        padding-left: 0;
        .group {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }
        .kpi {
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;
          opacity: 0.85;
          margin-right: 15px;
          .icon {
            margin-right: 4px;
            color: $gray4;
          }
          .value {
            font-size: 28px;
            font-weight: normal;
            color: $gray4;
            &.small {
              font-size: 18px;
            }

            &.xsmall {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
