@import '../../../theme.scss';

.wrapper {
  display: flex;
  align-items: center;
  transform: translateY(0);
  &.hasTime {
    transform: translateY(-5px);
  }
}

.date-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 110px;
}

.date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.hasTime {
    margin-top: 9px;
  }
}

.time {
  font-size: 0.7em;
  line-height: 0.7em;
  opacity: 0.5;
}

.divider {
  margin: 0 $space-0-5;
}
