@import '../../theme.scss';
@import '../../styles/animations.module.scss';

.submit-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: $space-0-5 $space-1;
  border-top: 1px solid $borderLight;
  border-left: 1px solid $borderLight;
}

.submit-container {
  display: flex;
  justify-content: flex-end;
}

.submit-button {
  animation: 0.1s growIn ease-in;
  margin: $space-0-5 0;
}
